<template>
  <b-overlay :show="submitDisabled">
    <div class="auth-wrapper auth-v2 login-bg">
      <b-row class="auth-inner m-0">

        <!-- Left Text-->
        <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
              v-if="false"
              fluid
              :src="imgUrl"
              alt="Login V2"
            />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Login-->
        <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5 login-bg-logo"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >

            <div class="text-center mb-2">
              <img
                src="@/assets/images/logo/logo-text.png"
                alt=""
                width="250px">
            </div>
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1 text-white text-center"
            >
              {{ appName }}
            </b-card-title>
            <b-card-text
              v-if="false"
              class="mb-2">
              Please sign-in to your account and start the adventure
            </b-card-text>

            <!-- form -->
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-5"
                @submit.prevent
              >
                <!-- username -->
                <b-form-group
                  label="Email"
                  label-for="login-username"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required"
                  >
                    <b-form-input
                      id="login-username"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="login-username"
                      placeholder="john@example.com"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Password</label>
                    <b-link
                      v-if="false"
                      :to="{name:'userRegister'}">
                      <small>Forgot Password?</small>
                    </b-link>
                    <b-link
                      v-if="false"
                      :to="{name:'auth-forgot-password-v2'}">
                      <small>Lupa Password?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group v-if="false">
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    Remember Me
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="success"
                  block
                  :disabled="submitDisabled"
                  @click="validationForm"
                >
                  Sign in
                </b-button>
              </b-form>
            </validation-observer>

            <b-card-text
              v-if="false"
              class="text-center mt-2">
              <span>Belum punya akun? </span>
              <b-link :to="{name:'userRegister'}">
                <span>Registrasi Pengguna</span>
              </b-link>
            </b-card-text>

            <!-- divider -->
            <div
              v-if="false"
              class="divider my-2">
              <div class="divider-text">
                or
              </div>
            </div>

            <!-- social buttons -->
            <div
              v-if="false"
              class="auth-footer-btn d-flex justify-content-center">
              <b-button
                variant="facebook"
                href="javascript:void(0)"
              >
                <feather-icon icon="FacebookIcon" />
              </b-button>
              <b-button
                variant="twitter"
                href="javascript:void(0)"
              >
                <feather-icon icon="TwitterIcon" />
              </b-button>
              <b-button
                variant="google"
                href="javascript:void(0)"
              >
                <feather-icon icon="MailIcon" />
              </b-button>
              <b-button
                variant="github"
                href="javascript:void(0)"
              >
                <feather-icon icon="GithubIcon" />
              </b-button>
            </div>
          </b-col>
        </b-col>
        <!-- /Login-->
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { nextTick } from '@vue/composition-api'
import { $themeConfig } from '@themeConfig'
// import Vue from 'vue'
// import { VueReCaptcha } from 'vue-recaptcha-v3'
// import ToastificationContent from '../@core/components/toastification/ToastificationContent.vue'
import useJwt from '../auth/jwt/useJwt'

// Vue.use(VueReCaptcha, { siteKey: '6LczsbcaAAAAACH8nfXZ47P2urtdEfIvRUgFGV1l' })

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    // VuexyLogo,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const { appName } = $themeConfig.app

    return {
      appName
    }
  },
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/klinik-bg.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      submitDisabled: false
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/klinik-bg.svg')
        return this.sideImg
      }
      return this.sideImg
    }
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        this.submitDisabled = true
        if (success) {
          // eslint-disable-next-line no-unused-vars
          // this.$recaptcha('contactus').then(token => {
          useJwt.login({
            grant_type: 'password',
            client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
            client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET,
            username: this.userEmail,
            // email: this.userEmail,
            password: this.password,
            c_password: this.password
          })
            .then(response => {
              console.log('UserLogin response', response)
              useJwt.setToken(response.data.access_token)
              useJwt.setRefreshToken(response.data.refresh_token)
              // localStorage.setItem('userData', JSON.stringify(response.data.user))
              // this.$router.push('/')

              useJwt.user().then(resp => {
                console.log('user', resp)
                localStorage.setItem('userData', JSON.stringify(resp.data.data))
                this.$ability.update(resp.data.data.ability)
                this.$store.commit('verticalMenu/UPDATE_USER_MENU', resp.data.data.menu)

                nextTick().then(() => {
                  window.location.reload()
                  // this.$router.push('/')
                })
              })
                .catch(() => {
                  this.submitDisabled = false
                })
            })
            .catch(() => {
              this.submitDisabled = false
            })
          // })
          //   // eslint-disable-next-line no-unused-vars
          //   .catch(err => {
          //     this.submitDisabled = false
          //   })
        } else {
          this.submitDisabled = false
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
